import axios from "axios";
import * as ACTION_TYPES from "./Types";
import {
  API_LOGIN,
  API_LOGOUT,
  API_CHANGE_PASS,
  API_FORGOT_PASS,
  API_RESET_PASS,
  API_UPDATE_PROFILE,
} from "../../constants/Api";

//To ReAuthenticate on HardLoading of WepApp
export const authenticate = (user) => (dispatch) => {
  if (user) {
    return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user });
  } else {
    return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED });
  }
};

//To Login
export const login = (user) => (dispatch) => {
  return axios
    .post(API_LOGIN, { ...user })
    .then((res) => {
      // set access token for rest of the api
      axios.defaults.headers.common["authorization"] =
        res.data.data.access_token;

      dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data });
      return res.data;
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response });
      throw err;
    });
};

//To Logout
export const logout = () => (dispatch) => {
  return axios
    .post(API_LOGOUT)
    .then((res) => {
      dispatch({ type: ACTION_TYPES.LOGOUT });
      return res.data;
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.LOGOUT });
      throw err;
    });
};

//To Change Password
export const changePass = (data) => (dispatch) => {
  return axios
    .post(API_CHANGE_PASS, data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.CHANGE_PASSWORD_ERROR,
        payload: err.response,
      });
      throw err;
    });
};
//To Forgot Password
export const forgotPass = (data) => (dispatch) => {
  return axios
    .post(API_FORGOT_PASS, data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.FORGOT_PASSWORD_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.FORGOT_PASSWORD_ERROR,
        payload: err.response,
      });
      throw err;
    });
};
//To Reset Password
export const resetPass = (id, values) => (dispatch) => {
  const data = {
    resetCode: id,
    password: values.newPassword,
    confirm_password: values.confirmPassword,
  };
  return axios
    .post(API_RESET_PASS, data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.RESET_PASSWORD_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.RESET_PASSWORD_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const updateProfile = (values) => (dispatch) => {
  let formData = new FormData();
  formData.append("firstName", values.firstName);
  formData.append("lastName", values.lastName);
  formData.append("adminId", values._id);

  if (values.upload_pic && values.upload_pic.length > 0) {
    let profileImage = values.upload_pic[0];
    formData.append("profilePicture", profileImage, profileImage.name);
  }

  return axios
    .post(API_UPDATE_PROFILE, formData)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROFILE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROFILE_ERROR,
        payload: err.response,
      });
      throw err;
    });
};
