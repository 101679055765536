import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as formReducer } from "redux-form";
import login from "./Login/Reducer";
import user from "./User/Reducer";
import draw from "./Draw/Reducer";
import winner from "./Winner/Reducer";
import CMS from "./CMS/Reducer";
import ticket from "./Ticket/Reducer";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  form: formReducer,
  login,
  user,
  draw,
  winner,
  CMS,
  ticket,
});

export default rootReducer;
