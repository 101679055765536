import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  consumerList: { total: 0, filteredTotal: 0 },
  consumer: {},
  start: 0,
  length: 10,
  flagCount: false,
  isEdited: false,
};

const consumerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LIST_SUCCESS:
      return {
        ...state,
        consumerList: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.CONSUMER_PAGE_CHANGE:
      return {
        ...state,
        start: action.payload,
      };
    case ACTION_TYPES.CONSUMER_LIMIT_CHANGE:
      return {
        ...state,
        length: action.payload,
      };
    case ACTION_TYPES.ADD_USER_SUCCESS:
    case ACTION_TYPES.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        consumer: action.payload.data,
        message: action.payload.message,
        isEdited: false,
        error: null,
      };
    case ACTION_TYPES.USER_ACTIVE_STATUS_SUCCESS:
    case ACTION_TYPES.EDIT_USER_SUCCESS:
      return {
        ...state,
        consumer: action.payload.data,
        message: action.payload.message,
        isEdited: true,
        error: null,
      };
    case ACTION_TYPES.FETCH_LIST_ERROR:
    case ACTION_TYPES.USER_ACTIVE_STATUS_ERROR:
    case ACTION_TYPES.EDIT_USER_ERROR:
    case ACTION_TYPES.USER_DEL_ERR:
      console.log(action);
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.message,
        isEdited: false,
      };
    case ACTION_TYPES.FETCH_USER_INFO_ERROR:
    case ACTION_TYPES.ADD_USER_ERROR:
    case ACTION_TYPES.USER_DEL_SUCC:
      return {
        ...state,
        consumer: null,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    default:
      return state;
  }
};

export default consumerReducer;
