export const { REACT_APP_API_BASE: API_BASE } = process.env;
console.log(API_BASE);
//AUTH Module
export const API_LOGIN = `${API_BASE}auth/login`;

export const API_LOGOUT = `${API_BASE}api/logout`;

export const API_CHANGE_PASS = `${API_BASE}api/changePassword`;

export const API_FORGOT_PASS = `${API_BASE}auth/forgotpassword`;

export const API_RESET_PASS = `${API_BASE}auth/resetPassword`;

export const API_UPDATE_PROFILE = `${API_BASE}api/updateProfile`;

//User Module
export const API_USERS_LIST = `${API_BASE}api/listUser`;

export const API_GET_USER = `${API_BASE}api/userDetail`;

export const API_UPT_USER = `${API_BASE}api/editUser`;

export const API_DEL_USER = `${API_BASE}api/deleteUser`;

export const API_ACT_STTS_USER = `${API_BASE}api/updateStatus`;

//draw Module
export const API_DRAWS_LIST = `${API_BASE}api/drawList`;

export const API_GET_DRAW = `${API_BASE}api/drawDetail`;

export const API_CREATE_DRAW = `${API_BASE}api/createDraw`;

export const API_UPT_DRAW = `${API_BASE}api/editDraw`;

export const API_DEL_DRAW = `${API_BASE}api/deleteDraw`;

//winner management
export const API_WINNERS_LIST = `${API_BASE}api/getAllWinner`;

//cms Module
export const API_CMS_LIST = `${API_BASE}api/cmsList`;

export const API_GET_CMS = `${API_BASE}api/getCms`;

export const API_CREATE_CMS = `${API_BASE}api/createCms`;

export const API_UPT_CMS = `${API_BASE}api/editCms`;

export const API_DEL_CMS = `${API_BASE}api/deleteCms`;

// ticket management
export const API_TICKETS_LIST = `${API_BASE}api/ticketList`;
