//FETCH_LIST Actions
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";

// //FLAG_CHANGE Actions
// export const DRAW_FLAG_CHANGE = "DRAW_FLAG_CHANGE"

//PAGE_CHANGE Actions
export const DRAW_PAGE_CHANGE = "DRAW_PAGE_CHANGE";

//LIMIT_CHANGE Actions
export const DRAW_LIMIT_CHANGE = "DRAW_LIMIT_CHANGE";

//FETCH_DRAW Actions
export const FETCH_DRAW_INFO_SUCCESS = "FETCH_DRAW_INFO_SUCCESS";
export const FETCH_DRAW_INFO_ERROR = "FETCH_DRAW_INFO_ERROR";

//ADD_DRAW Actions
export const ADD_DRAW_SUCCESS = "ADD_DRAW_SUCCESS";
export const ADD_DRAW_ERROR = "ADD_DRAW_ERROR";

//EDIT_DRAW Actions
export const EDIT_DRAW_SUCCESS = "EDIT_DRAW_SUCCESS";
export const EDIT_DRAW_ERROR = "EDIT_DRAW_ERROR";

//DRAW_ACTIVE_STATUS Actions
export const DRAW_ADD_SUCCESS = "DRAW_ADD_SUCCESS";
export const DRAW_ADD_ERROR = "DRAW_ADD_ERROR";

//DELETE_DRAW Actions
export const DRAW_DEL_SUCC = "DRAW_DEL_SUCC";
export const DRAW_DEL_ERR = "DRAW_DEL_ERR";
