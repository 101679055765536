import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  cmsData: {},
  isEdited: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CMS_SUCCESS:
      return {
        ...state,
        cmsData: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.FETCH_CMS_ERROR:
    case ACTION_TYPES.CMS_EDIT_ERROR:
    case ACTION_TYPES.UPD_CMS_DETAILS_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data.message,
        isEdited: false,
      };
    case ACTION_TYPES.CMS_EDIT_SUCCESS:
      return {
        ...state,
        cmsData: action.payload.data,
        message: action.payload.message,
        isEdited: true,
        error: null,
      };
    case ACTION_TYPES.UPD_CMS_DETAILS_SUCCESS:
      return {
        ...state,
        cmsData: action.payload.data,
        message: action.payload.message,
        isEdited: true,
        error: null,
      };
    default:
      return state;
  }
};
