import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

export default ComposedComponent => {

    const Authentication = props => {
        useEffect(() => {
            if (!props.isLoggedIn) {
                props.history.push('/login')
            }
            // eslint-disable-next-line
        }, [props.isLoggedIn])

        return <ComposedComponent {...props} />
    }

    const mapStateToProps = ({ login: { isLoggedIn } }) => ({ isLoggedIn })

    return withRouter(connect(mapStateToProps)(Authentication))
}
