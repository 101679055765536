import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  drawList: { total: 0, filteredTotal: 0 },
  draw: {},
  start: 0,
  length: 10,
  flagCount: false,
  isEdited: false,
};

const drawReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LIST_SUCCESS:
      return {
        ...state,
        drawList: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.DRAW_PAGE_CHANGE:
      return {
        ...state,
        start: action.payload,
      };
    case ACTION_TYPES.DRAW_LIMIT_CHANGE:
      return {
        ...state,
        length: action.payload,
      };
    case ACTION_TYPES.ADD_DRAW_SUCCESS:
    case ACTION_TYPES.FETCH_DRAW_INFO_SUCCESS:
      return {
        ...state,
        draw: action.payload.data,
        message: action.payload.message,
        isEdited: false,
        error: null,
      };
    case ACTION_TYPES.DRAW_ADD_SUCCESS:
    case ACTION_TYPES.EDIT_DRAW_SUCCESS:
      return {
        ...state,
        draw: action.payload.data,
        message: action.payload.message,
        isEdited: true,
        error: null,
      };
    case ACTION_TYPES.FETCH_LIST_ERROR:
    case ACTION_TYPES.DRAW_ADD_ERROR:
    case ACTION_TYPES.EDIT_DRAW_ERROR:
    case ACTION_TYPES.DRAW_DEL_ERR:
      console.log(action);
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.message,
        isEdited: false,
      };
    case ACTION_TYPES.FETCH_DRAW_INFO_ERROR:
    case ACTION_TYPES.ADD_DRAW_ERROR:
    case ACTION_TYPES.DRAW_DEL_SUCC:
      return {
        ...state,
        draw: null,
        message: action.payload.data.message,
        error: action.payload.data.message,
      };
    default:
      return state;
  }
};

export default drawReducer;
