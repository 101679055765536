//FETCH_LIST Actions
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_ERROR = "FETCH_LIST_ERROR";

// //FLAG_CHANGE Actions
// export const USER_FLAG_CHANGE = "USER_FLAG_CHANGE"

//PAGE_CHANGE Actions
export const CONSUMER_PAGE_CHANGE = "CONSUMER_PAGE_CHANGE";

//LIMIT_CHANGE Actions
export const CONSUMER_LIMIT_CHANGE = "CONSUMER_LIMIT_CHANGE";

//FETCH_CONSUMER Actions
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";

//ADD_CONSUMER Actions
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

//EDIT_CONSUMER Actions
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

//CONSUMER_ACTIVE_STATUS Actions
export const USER_ACTIVE_STATUS_SUCCESS = "USER_ACTIVE_STATUS_SUCCESS";
export const USER_ACTIVE_STATUS_ERROR = "USER_ACTIVE_STATUS_ERROR";

//DELETE_CONSUMER Actions
export const USER_DEL_SUCC = "USER_DEL_SUCC";
export const USER_DEL_ERR = "USER_DEL_ERR";
