//FETCH_CMS Actions
export const FETCH_CMS_SUCCESS = "FETCH_CMS_SUCCESS";
export const FETCH_CMS_ERROR = "FETCH_CMS_ERROR";

// CMS EDIT Actions
export const CMS_EDIT_SUCCESS = "CMS_EDIT_SUCCESS";
export const CMS_EDIT_ERROR = "CMS_EDIT_ERROR";

// CMS EDIT Actions
export const UPD_CMS_DETAILS_SUCCESS = "UPD_CMS_DETAILS_SUCCESS";
export const UPD_CMS_DETAILS_ERROR = "UPD_CMS_DETAILS_ERROR";

export const CMS_DEL_SUCCESS = "CMS_DEL_SUCCESS";
export const CMS_DEL_ERROR = "CMS_DEL_ERROR";

// CMS EDIT Actions
export const CMS_ADD_SUCCESS = "CMS_ADD_SUCCESS";
export const CMS_ADD_ERROR = "CMS_ADD_ERROR";
