import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  userData: {},
  isoggedIn: false,
  passwordChange: false,
  isProfileUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      console.log(action);
      if (action.payload.status === 200) {
        localStorage.setItem(
          "authToken",
          JSON.stringify(action.payload.data.access_token)
        );
        localStorage.setItem("user", JSON.stringify(action.payload.data));
        return {
          ...state,
          userData: action.payload.data,
          message: action.payload.message,
          isLoggedIn: true,
          error: null,
        };
      } else {
        return {
          ...state,
          message: action.payload.message,
          isLoggedIn: false,
          error: action.payload.message,
        };
      }
    case ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload.data));
      return {
        ...state,
        userData: action.payload.data,
        message: action.payload.message,
        isLoggedIn: true,
      };

    case ACTION_TYPES.LOGIN_ERROR:
    case ACTION_TYPES.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        message: action.payload.data.message,
        error: action.payload.data,
      };

    case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        passwordChange: true,
        error: null,
      };
    case ACTION_TYPES.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.data,
      };

    case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        //passwordChange: true,
        error: null,
      };
    case ACTION_TYPES.FORGOT_PASSWORD_ERROR:
    case ACTION_TYPES.RESET_PASSWORD_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.data,
      };
    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null,
      };
    case ACTION_TYPES.LOGOUT:
    case ACTION_TYPES.AUTHENTICATE_FAILED:
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        userData: {},
      };

    default:
      return state;
  }
};
