import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  ticketList: { totalRecords: 0, list: [] },
  ticket: {},
  start: 1,
  length: 10,
  flagCount: false,
  isEdited: false,
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LIST_SUCCESS:
      return {
        ...state,
        ticketList: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.TICKET_PAGE_CHANGE:
      return {
        ...state,
        start: action.payload,
      };
    case ACTION_TYPES.TICKET_LIMIT_CHANGE:
      return {
        ...state,
        length: action.payload,
      };

    case ACTION_TYPES.FETCH_LIST_ERROR:
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.message,
        isEdited: false,
      };

    default:
      return state;
  }
};

export default ticketReducer;
