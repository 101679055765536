import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  winnerList: { total: 0, filteredTotal: 0 },
  winner: {},
  start: 0,
  length: 10,
  flagCount: false,
  isEdited: false,
};

const winnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LIST_SUCCESS:
      return {
        ...state,
        winnerList: action.payload.data,
        message: action.payload.message,
        error: null,
      };
    case ACTION_TYPES.WINNER_PAGE_CHANGE:
      return {
        ...state,
        start: action.payload,
      };
    case ACTION_TYPES.WINNER_LIMIT_CHANGE:
      return {
        ...state,
        length: action.payload,
      };

    default:
      return state;
  }
};

export default winnerReducer;
